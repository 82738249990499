<div class="loader-container">
  <app-loader [isActive]="isLoading.value" [enableCircle]="true" [width]="20"></app-loader>
  <h1 matDialogTitle>
    <div class="flex flex-row items-center justify-start">
      <div>{{ 'task.add' | translate }}</div>
      <div class="flex-auto"></div>
      <button mat-icon-button matDialogClose tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </h1>
  <mat-dialog-content cdkTrapFocus>
    <form
      (submit)="$event.preventDefault()"
      class="flex flex-col items-stretch justify-start py-2"
      [formGroup]="this.taskGroup"
    >
      <div class="flex flex-col">
        <mat-form-field class="w-full">
          <mat-label>{{ 'Name' | translate }}</mat-label>
          <input type="text" formControlName="name" matInput required cdkInitialFocus />
          <mat-error *ngIf="getFormErrorByName('name') as error">{{
            error.content | translate: error.args || {}
          }}</mat-error>
        </mat-form-field>
        <div class="flex flex-col space-y-0">
          <mat-form-field>
            <mat-label>{{ 'project.select' | translate }}</mat-label>
            <input
              type="text"
              matInput
              disabled
              [value]="
                taskGroup.value.project?.name
                  ? (taskGroup.value.project | defaultEntityTranslate: 'project':'project.none')
                  : null
              "
            />
            <mat-error *ngIf="!taskGroup.value.project"
              >{{ 'errors.required' | translate: { field: 'project.select' | translate } }} *</mat-error
            >
          </mat-form-field>
          <div
            *ngIf="(taskGroup.value.project | projectProxy)?.description as desc"
            class="rounded bg-neutral-800/5 px-2.5 py-3 text-sm dark:bg-black/20"
          >
            {{ desc }}
          </div>
        </div>
        <tg-duration-input-control [inputPlaceholder]="'timer.time.duration' | translate" formControlName="estimation">
          <ng-template durationInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
            <mat-form-field fxFill>
              <mat-label>{{ 'task.estimation' | translate }}</mat-label>
              <input type="text" matInput [formControl]="control" autocomplete="off" />
            </mat-form-field>
          </ng-template>
        </tg-duration-input-control>
        <div class="flex flex-col space-y-4">
          <div class="flex flex-col space-y-2">
            <div class="mat-hint text-sm" translate>task.assigned-users-or-groups</div>
            <tg-user-group-select-control
              formControlName="assigned"
              [filterSearch]="filterProjectExclusive"
            ></tg-user-group-select-control>
          </div>
        </div>
        <div class="h-10"></div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="mat-action-filled">
    <button
      mat-flat-button
      class="mat-action-filled"
      color="primary"
      (click)="createTask()"
      [disabled]="!(project$createPermission | async)"
    >
      {{ 'task.add' | translate }}
    </button>
  </mat-dialog-actions>
</div>
<ng-template #noSelectedUsers>
  <button mat-button (click)="openUserDialog()">
    {{ 'workspace.groups.user-add' | translate }}
  </button>
</ng-template>
<ng-template #userTemplate let-user="user">
  <div class="h-10 w-10" matTooltip [matTooltipTemplate]="userHoverCard" [matTooltipTemplateContext]="{ user: user }">
    <app-user-avatar [userId]="user.id" size="100%" class="rounded-full"></app-user-avatar>
  </div>
</ng-template>
<ng-template #userHoverCard let-user="user">
  <div class="-mx-1 flex flex-row items-center justify-start gap-2">
    <app-user-avatar
      size="40px"
      class="rounded-xs"
      [userId]="user.id"
      matTooltip
      [matTooltipTemplate]
      defaultImageSource="/assets/img/default.png"
    ></app-user-avatar>
    <div class="mat-typography flex flex-col">
      <div>{{ user.name }}</div>
      <div class="mat-hint">{{ user.email | truncate: 42 }}</div>
    </div>
  </div>
</ng-template>
