import { Injectable } from '@angular/core';
import * as teamsLib from '@microsoft/teams-js';
export enum FailedReason {
  /**
   * Authentication failed
   */
  AuthFailed = 'AuthFailed',
  /**
   * The application timed out
   */
  Timeout = 'Timeout',
  /**
   * The app failed for a different reason
   */
  Other = 'Other',
}

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  private _instance: typeof teamsLib;
  constructor() {}

  async initialize() {
    if (!this._instance) this._instance = teamsLib;
    else window.microsoftTeams = this._instance; // add teams instance to window for api library
    await this._instance.app.initialize();
  }
  getInstance() {
    return this._instance;
  }
  isInitialized() {
    return !!this._instance?.app.isInitialized();
  }
}
