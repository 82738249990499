<mat-dialog-content>
  <div class="searchWrapper mt-2 flex flex-shrink-0 flex-col justify-stretch">
    <div class="searchInput flex flex-col items-start justify-center px-2">
      <mat-form-field appearance="fill" class="mat-form-field-compact mat-form-field-no-hint w-full">
        <input type="text" matInput [formControl]="searchinput" cdkFocusInitial tabindex="0" ignoreAutocomplete />
        <mat-icon inline matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="searchContainer pos-relative flex flex-col">
      <app-loader [isActive]="this.isLoading$ | async" [enableCircle]="true" [width]="20"></app-loader>
      <mat-list dense role="listbox">
        <ng-container
          *ngFor="let entry of this.data$filtered | async; let last = last; let first = first; trackBy: trackFiltered"
        >
          <div [matTooltip]="entry.selected ? null : entry.exists ? ('errors.duplicatenames' | translate) : null">
            <mat-list-item
              (keyup.enter)="this.selectItem(entry)"
              (click)="this.selectItem(entry)"
              class="cursor-pointer truncate"
              matRipple
              [disabled]="entry.disabled"
            >
              <div class="flex items-center space-x-2 truncate">
                <div
                  [ngClass]="{
                    radio: true,
                    active: entry.selected,
                    disabled: entry.disabled
                  }"
                ></div>
                <span class="truncate"> {{ entry.name }} </span>
              </div>
            </mat-list-item>
          </div>
          <mat-divider *ngIf="!last"></mat-divider>
        </ng-container>
      </mat-list>
    </div>
  </div>
  <div class="flex-auto"></div>
  <div class="flex flex-col items-stretch justify-end">
    <div class="flex flex-col">
      <button
        class="mat-button-filled entry-create-button"
        mat-flat-button
        color="primary"
        (click)="this.createDialog()"
        tabindex="0"
        [disabled]="!(workspace$createPermission | async)"
      >
        {{ 'client.create' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>
