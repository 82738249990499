<div class="flex h-full min-h-0 flex-col gap-4">
  <div class="mx-4 flex">
    <mat-form-field appearance="fill" class="mat-form-field-no-hint flex-auto">
      <mat-label>{{ 'utils.search' | translate }}</mat-label>
      <input
        matInput
        type="text"
        ignoreAutocomplete
        cdkInitialFocus
        [formControl]="search"
        #searchElement
        cdkTrapFocus
        [cdkTrapFocusAutoCapture]="true"
      />
    </mat-form-field>
  </div>
  @if (entries$filtered | async; as entries) { @if (entries.length) {
  <div cdkVirtualScrollingElement class="scroll-y h-[500px] min-h-0">
    <cdk-virtual-scroll-viewport autosize class="h-full" [minBufferPx]="600" [maxBufferPx]="1200">
      <div *cdkVirtualFor="let entry of entries; trackBy: trackId">
        <mat-card
          class="mat-card-button !mat-bg mx-4 mb-4 cursor-pointer"
          (click)="submitTask({ task: entry })"
          (contextmenu)="openContextMenu($event, contextMenuTrigger, { entry: entry })"
          [class.border-active]="entry.selected"
        >
          <mat-card-content class="flex">
            <div class="truncate-flex flex flex-auto flex-col">
              <div class="flex flex-row gap-2">
                @if(entry.color; as color) {
                <div
                  class="project-color-dot self-center"
                  [style.backgroundColor]="color"
                  [matTooltip]="'project.color' | translate"
                ></div>
                }
                <h3 class="mb-0 truncate text-base font-bold">{{ entry.name | truncate: 100 }}</h3>
                <div class="!ml-auto flex flex-row items-center justify-start gap-2">
                  <mat-icon
                    class="bg-accent-2 rounded-circle flex-none !p-1 text-lg"
                    inline
                    color="accent"
                    *ngIf="entry.billable"
                    [matTooltip]="'Billable_Alt' | translate"
                    >attach_money</mat-icon
                  >
                  <mat-icon
                    class="bg-default-2 rounded-circle flex-none !p-1 text-lg"
                    inline
                    *ngIf="entry.private"
                    [matTooltip]="'project.private.state' | translate"
                    >lock</mat-icon
                  >
                </div>
              </div>
              <div class="mat-hint flex items-center space-x-2">
                <span>{{ entry.client | defaultEntityTranslate: 'client':'client.none' | truncate: 100 }}</span>
                <span class="dot size-1.5"></span>
                <span>{{ entry.project | defaultEntityTranslate: 'project':'project.none' | truncate: 100 }}</span>
              </div>
              <div
                *ngIf="entry.description as desc"
                class="mat-typography whitespace-pre-wrap"
                [innerText]="desc | truncate: 200 | trim"
              ></div>
            </div>
            <div class="flex items-center justify-center self-start">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </cdk-virtual-scroll-viewport>
    <!-- <div class="pb-48"></div> -->
  </div>
  } @else {
  <ng-container *ngTemplateOutlet="noItems"></ng-container>

  } } @else {

  <ng-container *ngTemplateOutlet="noItems"></ng-container>

  }
</div>
<ng-template #noItems>
  <div class="flex h-[500px] min-h-0 flex-col items-center justify-center">
    {{ 'utils.noitems' | translate }}
  </div>
</ng-template>

<mat-menu #itemContextMenu>
  <ng-template matMenuContent let-entry="entry">
    <div class="flex flex-col space-y-2">
      <a
        mat-menu-item
        [routerLink]="['/settings/projects', selectedEntity.project.id]"
        (click.prevent)="openProject(selectedEntity.project.id)"
        [disabled]="isDefaultProject$ | async"
      >
        <mat-icon inline>folder</mat-icon> {{ 'project.open' | translate }}
      </a>
    </div>
  </ng-template>
</mat-menu>
<div
  #contextMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="itemContextMenu"
  style="visibility: hidden; position: fixed"
  [style.left]="projectMenuPosition.x"
  [style.top]="projectMenuPosition.y"
></div>
<ng-template #projectPickerTooltip let-project="project" let-tags="tags">
  <div class="my-2 flex flex-col space-y-2 px-2.5 wflex-[1_0_220px]">
    <div class="space-y-1">
      <h3 class="mat-hint text-sm italic" translate>client.single</h3>
      <div class="font-semibold">{{ project?.client | defaultEntityTranslate: 'client':'client.none' }}</div>
    </div>
    <div class="space-y-1">
      <h3 class="mat-hint text-sm italic" translate>project.single</h3>
      <div class="font-semibold">{{ project | defaultEntityTranslate: 'task':'task.none' }}</div>
    </div>
    <div class="space-y-1" *ngIf="project?.description as desc">
      <h3 class="mat-hint text-sm italic" translate>Description</h3>
      <div>{{ desc | truncate: 120 }}</div>
    </div>
    <ng-container *ngIf="tags && tags.length > 0">
      <div class="space-y-1">
        <h3 class="mat-hint text-sm italic" translate>tag.plural</h3>
        <div class="flex flex-wrap gap-2">
          <div
            class="mbg-card pointer-events-none h-6 cursor-default select-none truncate rounded-full px-2.5 text-base leading-6"
            *ngFor="let item of tags; trackBy: trackId"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
