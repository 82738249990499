import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { endOfDay } from 'date-fns/esm';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { UserSettingsQuery } from 'timeghost-api';
import { TimeDatePickerConfig } from './time-date-picker-config';

@Component({
  selector: 'app-time-date-picker',
  templateUrl: './time-date-picker.component.html',
  styleUrls: ['./time-date-picker.component.scss'],
})
export class TimeDatePickerComponent implements OnInit, AfterViewInit {
  @ViewChild('calview')
  calendar: MatCalendar<Date>;
  private _selectedDate = new BehaviorSubject<Date>(new Date());
  readonly selectedDate$ = this._selectedDate.asObservable().pipe(distinctUntilChanged());
  get selectedDate() {
    return this._selectedDate.getValue();
  }
  set selectedDate(val: Date) {
    this._selectedDate.next(val);
  }
  settings$allowFutureTimeTracking = this.userSettingsQuery
    .select((x) => x.workspace.settings)
    .pipe(map((x) => (x.allowFutureTimeTracking ? null : endOfDay(new Date()))));

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: TimeDatePickerConfig,
    private userSettingsQuery: UserSettingsQuery,
    private ref: MatDialogRef<TimeDatePickerComponent>
  ) {}

  ngOnInit(): void {
    this.selectedDate = this.data.selectedDate;
    this.ref.addPanelClass('mat-dialog-nopad'), this.ref.updateSize('320px');
  }
  ngAfterViewInit() {}
  close(val: Date) {
    return this.ref.close(val);
  }
}
