<div class="image-wrapper">
  <ng-container *ngIf="!(this.isLoading$ | async); else skeleton">
    <ng-container *ngIf="this.imageSrc$ | async as imageSrcData; else defaultAvatar">
      <img [src]="imageSrcData" class="image-inner" alt="user-avatar" />
    </ng-container>
    <ng-template #defaultAvatar>
      <ng-container *ngIf="this.defaultImageTemplate as _template; else showDefaultImageSource">
        <ng-container *ngTemplateOutlet="_template; context: this.defaultImageTemplateData || {}"></ng-container>
      </ng-container>
      <ng-template #showDefaultImageSource>
        <img [src]="this.defaultImageSource$ | async | safeUrl" class="image-inner" alt="default-user-avatar" />
      </ng-template>
    </ng-template>
  </ng-container>
  <ng-template #skeleton>
    <div appearance="circle" class="loader-inner image-inner mat-skeleton mat-skeleton flex flex-grow flex-col"></div>
  </ng-template>
</div>
