<app-loader [isActive]="this.isLoading$ | async" [isAfterViewInit]="false" [enableCircle]="true"></app-loader>
<h1 matDialogTitle>
  <div class="flex flex-row items-center justify-start">
    <div>{{ (requireSplit ? 'time.create' : 'time.update') | translate }}</div>
    <div class="flex-auto"></div>
    <div class="flex items-center px-2" *ngIf="isRecording">
      <ng-container *ngTemplateOutlet="recordingTemplate"></ng-container>
    </div>
    <button mat-icon-button matDialogClose [disabled]="closeDisabled" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h1>
<mat-dialog-content cdkTrapFocus>
  <form (submit)="$event.preventDefault()" [formGroup]="group">
    <div class="flex flex-col items-stretch justify-start gap-2">
      <mat-form-field *ngIf="{ error: getErrorObservable('name') | async } as ctrl">
        <mat-label>{{ 'time.select' | translate }}</mat-label>
        <input type="text" matInput formControlName="name" cdkInitialFocus />
        <mat-hint *ngIf="(requireFields$ | async)?.name as fields">{{
          'errors.required' | translate: ({ field: 'time.name' } | translateArgs)
        }}</mat-hint>
        <mat-error *ngIf="ctrl.error as error">{{ error.content | translate: error.args | translateArgs }}</mat-error>
      </mat-form-field>
      <div class="flex flex-col space-y-0">
        <mat-form-field
          (click)="$event.preventDefault(); this.openProjectPicker()"
          *ngIf="{ error: getErrorObservable('project') | async } as ctrl"
          class="cursor-pointer"
        >
          <mat-label>{{ 'project.select' | translate }}</mat-label>
          <input
            class="cursor-pointer"
            type="text"
            matInput
            readonly
            [isError]="group.controls.project.touched && ctrl.error"
            [ngModel]="
              group.value.project?.name
                ? (group.value.project | defaultEntityTranslate: 'project':'project.none')
                : null
            "
            [ngModelOptions]="{ standalone: true }"
          />
          <button
            *ngIf="group.value.project && this.defaultProject.id !== group.value.project.id"
            mat-icon-button
            matSuffix
            type="button"
            (click)="$event.preventDefault(); $event.stopPropagation(); resetProject()"
          >
            <mat-icon>clear</mat-icon>
          </button>
          <mat-hint *ngIf="(requireFields$ | async)?.project as fields">{{
            'errors.record.project-req' | translate
          }}</mat-hint>
          <mat-error *ngIf="ctrl.error as error">{{ error.content | translate: error.args || {} }}</mat-error>
        </mat-form-field>
        <div
          *ngIf="(group.value.project | projectProxy)?.description as desc"
          class="mb-4 rounded bg-neutral-800/5 px-2.5 py-3 text-sm dark:bg-black/20"
        >
          {{ desc }}
        </div>
      </div>
      <ng-container *ngIf="group.value.project?.tags?.length">
        <div class="flex flex-wrap gap-2">
          <div
            class="mbg-card pointer-events-none h-6 cursor-default select-none rounded-full px-2.5 text-base leading-6"
            *ngFor="let item of group.value.project?.tags; trackBy: trackId"
          >
            {{ item.name }}
          </div>
        </div>
      </ng-container>
      <mat-form-field
        class="w-full"
        (click)="$event.preventDefault(); this.openTaskPicker()"
        *ngIf="{ error: getErrorObservable('task') | async } as ctrl"
        [class.cursor-pointer]="!taskDisabled"
      >
        <mat-label>{{ 'task.select' | translate }}</mat-label>
        <input
          [class.cursor-pointer]="!taskDisabled"
          type="text"
          matInput
          readonly
          [ngModel]="group.value.task?.name"
          [ngModelOptions]="{ standalone: true }"
          [isError]="group.controls.task.touched && ctrl.error"
          [disabled]="taskDisabled"
          #taskPickerInput
        />
        <button
          *ngIf="group.value.task"
          mat-icon-button
          matSuffix
          type="button"
          [disabled]="taskPickerInput.disabled"
          (click)="$event.preventDefault(); $event.stopPropagation(); group.patchValue({ task: null })"
        >
          <mat-icon>clear</mat-icon>
        </button>
        <mat-hint *ngIf="(requireFields$ | async)?.task as fields">{{
          'errors.record.desc-required' | translate
        }}</mat-hint>
        <mat-error *ngIf="ctrl.error as error">{{ error.content | translate: error.args || {} }}</mat-error>
      </mat-form-field>
      <div class="flex w-full cursor-pointer flex-col">
        <mat-form-field (click)="!$event.defaultPrevented && openTagsPicker()">
          <mat-label>{{ 'tag.select' | translate }}</mat-label>
          <input
            class="cursor-pointer"
            type="text"
            matInput
            readonly
            [value]="group.value.tags | mapProp: 'name' | joinString: ', '"
          />
          <button
            *ngIf="group.value.tags && group.value.tags.length > 0"
            mat-icon-button
            matSuffix
            type="button"
            (click.native)="group.controls.tags.setValue([])"
          >
            <mat-icon>clear</mat-icon>
          </button>
          <mat-error *ngIf="!group.value.tags"
            >{{ 'errors.required' | translate: { field: 'Tags' | translate } }} *</mat-error
          >
        </mat-form-field>
      </div>
      <div
        class="flex w-full cursor-pointer flex-col"
        (click)="$event.preventDefault(); openUserPicker()"
        *ngIf="(workspace$isAdmin.value$ | async) && !isRecording"
      >
        <mat-form-field class="form-control-interactive">
          <mat-label>{{ 'user.select' | translate }}</mat-label>
          <div *ngIf="group.value.user as user" class="-mb-16px flex items-center space-x-1.5">
            <app-user-avatar
              size="40px"
              class="rounded-full"
              [userId]="user.id"
              defaultImageSource="/assets/img/default.png"
            ></app-user-avatar>
            <div class="flex flex-auto flex-col leading-none">
              <span>{{ user.name }}</span>
              <span class="mat-hint text-sm">{{ user.email }}</span>
            </div>
            <button
              *ngIf="postDiffUser$ | async"
              mat-icon-button
              (click)="$event.preventDefault(); $event.stopPropagation(); patchValue({ user: null })"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
          <input matInput [value]="group.value.user" readonly class="!pointer-events-none" />
        </mat-form-field>
      </div>
      <div
        class="flex w-full cursor-pointer flex-col"
        (click)="$event.preventDefault(); openCalPicker()"
        *ngIf="!isRecording"
      >
        <mat-form-field class="pointer-events-none">
          <mat-label>{{ 'timer.date' | translate }}</mat-label>
          <input matInput [value]="group.value.date | formatDate" class="!pointer-events-none" />
        </mat-form-field>
      </div>
      <div class="flex items-center space-x-2">
        <ng-container
          *ngTemplateOutlet="
            isRecording
              ? requireSplit
                ? splitModeTemplate
                : null
              : mode === 'range'
              ? rangeModeTemplate
              : mode === 'range_optional'
              ? stateVisibleMode
                ? rangeModeTemplate
                : durationModeTemplate
              : mode === 'duration'
              ? durationModeTemplate
              : null
          "
        ></ng-container>
        <ng-container *ngIf="!isRecording && mode === 'range_optional'">
          <button
            mat-icon-button
            [matTooltip]="'timer.time.changeInputMode' | translate"
            [matTooltipOptions]="{ hideOnClick: false }"
            (click)="stateVisibleMode = !stateVisibleMode"
            type="button"
          >
            <mat-icon>swap_horiz</mat-icon>
          </button>
        </ng-container>
      </div>
      <mat-checkbox formControlName="billable" class="mb-4" color="primary">{{
        'Billable_Alt' | translate
      }}</mat-checkbox>
    </div>
    <ng-template #splitModeTemplate>
      <div class="mb-2 flex flex-auto flex-col space-y-2">
        <div
          *ngFor="let item of splitTimes$ | async; let spIndex = index; trackProp: 'date'"
          class="mat-typography mat-bg-2 flex h-10 cursor-pointer items-center space-x-4 rounded px-4 shadow"
          (click)="toggleSplitTime(spIndex)"
        >
          <div class="flex flex-auto select-none space-x-2">
            <div>{{ item.start | dfnsFormat: item.format }}</div>
            <mat-icon inline>keyboard_arrow_right</mat-icon>
            <div>{{ item.end | dfnsFormat: item.format }}</div>
          </div>
          <div class="flex-none rounded-full bg-green-500 p-1 text-white" *ngIf="item.selected" @matScale>
            <div class="mat-size-2">
              <mat-icon inline>check</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #rangeModeTemplate>
      <div class="flex flex-1 flex-col" *ngIf="{ error: groupErrors } as ctrl">
        <div class="flex flex-row items-center justify-between">
          <app-time-input
            [inputPlaceholder]="'timer.time.start' | translate"
            matInput
            formControlName="start"
            [displayFormat]="false"
            #tStartFormField
            [maxlength]="5"
            elementRef
            #startInpRef="nativeElement"
            (focus)="this.selectInput($event, startInpRef)"
            (keydown.enter)="$event.preventDefault()"
            (contextmenu)="
              this.onTimeContextMenuTrigger($event, {
                time: this.group.value.start,
                prop: 'start'
              })
            "
            (click)="$event.stopPropagation(); this.selectInput($event, startInpRef)"
            class="wflex-[0_0_74px]"
          >
            <ng-template timeInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
              <mat-form-field fxFill>
                <mat-label translate>timer.time.start</mat-label>
                <input
                  type="text"
                  matInput
                  [placeholder]="inputPlaceholder"
                  [formControl]="control"
                  [isError]="(group.errors?.same && group.errors) || group.errors?.range"
                />
              </mat-form-field>
            </ng-template>
          </app-time-input>
          <span class="tg-seperator mx-2">
            <mat-icon>arrow_forward</mat-icon>
          </span>
          <app-time-input
            [inputPlaceholder]="'timer.time.end' | translate"
            matInput
            formControlName="end"
            [displayFormat]="false"
            #tEndFormField
            [maxlength]="5"
            elementRef
            #endInpRef="nativeElement"
            (focus)="this.selectInput($event, endInpRef)"
            (keydown.enter)="$event.preventDefault()"
            (contextmenu)="
              this.onTimeContextMenuTrigger($event, {
                time: this.group.value.end,
                prop: 'end'
              })
            "
            (click)="$event.stopPropagation(); this.selectInput($event, endInpRef)"
            class="wflex-[0_0_74px]"
          >
            <ng-template timeInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
              <mat-form-field fxFill>
                <mat-label translate>timer.time.end</mat-label>
                <input
                  type="text"
                  matInput
                  [placeholder]="inputPlaceholder"
                  [formControl]="control"
                  [isError]="(group.errors?.same && group.errors) || group.errors?.range"
                />
              </mat-form-field>
            </ng-template>
          </app-time-input>
        </div>
        <div class="mat-error mat-typography -mt-4 text-sm" *ngIf="ctrl.error as err">
          {{ err | translate }}
        </div>
      </div>
    </ng-template>
    <ng-template #durationModeTemplate>
      <div class="flex flex-1 flex-col">
        <tg-duration-input-control formControlName="duration" displayFormat="HH:mm">
          <ng-template
            durationInputControl
            let-control="control"
            let-inputPlaceholder="inputPlaceholder"
            let-name="name"
          >
            <mat-form-field fxFill>
              <mat-label translate>timer.time.duration</mat-label>
              <input type="text" matInput [formControl]="control" />
            </mat-form-field>
          </ng-template>
        </tg-duration-input-control>
      </div>
    </ng-template>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="mat-action-filled">
  <button
    mat-flat-button
    class="mat-action-filled"
    color="primary"
    (click)="update()"
    [disabled]="(this.group.invalid && !forceUpdate) || !(splitTimes$valid | async)"
  >
    {{ (requireSplit ? 'time.create' : 'time.update') | translate }}
  </button>
</mat-dialog-actions>
<mat-menu #timeContextMenu="matMenu">
  <ng-template matMenuContent let-data>
    <button mat-menu-item (click)="setToNow(data.prop)">
      {{ 'timeContextMenu.setTo.now' | translate }}
    </button>
    <button mat-menu-item [matMenuTriggerFor]="timeContextMenuTimeTemplates" [matMenuTriggerData]="{ $implicit: data }">
      {{ 'timeContextMenu.setToMore' | translate }}
    </button>
  </ng-template>
</mat-menu>
<mat-menu #timeContextMenuTimeTemplates="matMenu">
  <ng-template matMenuContent let-data>
    <button mat-menu-item (click)="setMinutesDiff(5, data.prop)">
      {{ 'timeContextMenu.setTo.MinDiffs' | translate: { value: 5 } }}
    </button>
    <button mat-menu-item (click)="setMinutesDiff(10, data.prop)">
      {{ 'timeContextMenu.setTo.MinDiffs' | translate: { value: 10 } }}
    </button>
    <button mat-menu-item (click)="setMinutesDiff(15, data.prop)">
      {{ 'timeContextMenu.setTo.MinDiffs' | translate: { value: 15 } }}
    </button>
    <button mat-menu-item (click)="setMinutesDiff(30, data.prop)">
      {{ 'timeContextMenu.setTo.MinDiffs' | translate: { value: 30 } }}
    </button>
    <button mat-menu-item (click)="setHourDiff(1, data.prop)">
      {{ 'timeContextMenu.setTo.HrDiff' | translate: { value: 1 } }}
    </button>
    <button mat-menu-item (click)="setHourDiff(2, data.prop)">
      {{ 'timeContextMenu.setTo.HrDiffs' | translate: { value: 2 } }}
    </button>
    <button mat-menu-item (click)="setHourDiff(4, data.prop)">
      {{ 'timeContextMenu.setTo.HrDiffs' | translate: { value: 4 } }}
    </button>
    <button mat-menu-item (click)="setWorkDay()">
      {{ 'timeContextMenu.setTo.1workDay' | translate }}
    </button>
  </ng-template>
</mat-menu>
<div
  style="visibility: hidden; position: fixed"
  [style.left]="timeContextMenuPosition.x"
  [style.top]="timeContextMenuPosition.y"
  #timeContextMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="timeContextMenu"
></div>
<ng-template #recordingTemplate>
  <div class="mat-typography flex flex-auto items-center justify-center space-x-1.5 text-red-500">
    <div class="size-2.5 animate-pulse rounded-full bg-red-500"></div>
    <div class="leading-tight">{{ 'time.recording' | translate }}</div>
  </div>
</ng-template>
