import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { clampDay } from '@app/_helpers/date-fns';
import { debounceTimeAfterFirst } from '@app/_helpers/debounceAfterTime';
import { ObserveFormGroupErrors, useFormErrorObservable } from '@app/_helpers/get-error-observable';
import { pushError } from '@app/_helpers/globalErrorHandler';
import { isBreakRuleError } from '@app/_helpers/is-error-object';
import {
  WithOptional,
  coerceTimeFormat,
  distinctUntilChangedJson,
  fromRxValue,
  resolveRawArgs,
  roundUpMinute,
} from '@app/_helpers/utils';
import { CustomValidators } from '@app/_validators/custom-validators';
import {
  ComeAndGoCreateDialogComponent,
  WorkingHoursCreateData,
} from '@app/components/come-and-go-create-dialog/come-and-go-create-dialog.component';
import { SONNER_DEFAULT_CONFIG } from '@app/config/sonner';
import { extract } from '@app/core';
import { EntityStore } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { CalendarEvent } from 'calendar-utils';
import { intervalToDuration } from 'date-fns';
import differenceInHours from 'date-fns/differenceInHours';
import {
  addHours,
  addMinutes,
  addSeconds,
  endOfDay,
  endOfToday,
  format as formatString,
  isSameDay,
  isToday,
  parse as parseFromString,
  roundToNearestMinutes,
  startOfDay,
  startOfMinute,
  startOfToday,
  subHours,
  subMinutes,
} from 'date-fns/esm';
import produce from 'immer';
import { flow, merge } from 'lodash-es';
import { MediaObserver } from 'ngx-flexible-layout';
import { ExternalToast, toast } from 'ngx-sonner';
import { BehaviorSubject, Subject, combineLatest, firstValueFrom } from 'rxjs';
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  ApplicationSettingsQuery,
  ComegoQuery,
  ComegoService,
  ComegoTime,
  Logger,
  MyTimesQuery,
  MyTimesService,
  Project,
  ProjectsQuery,
  Tag,
  Task,
  Time,
  UserSettingsQuery,
} from 'timeghost-api';
import { MyTimesStore } from 'timeghost-api/lib/stores/myTimes/myTimes.store';
import {
  TimeTrackCreateData,
  TimeTrackerCalendarCreateDialogComponent,
} from '../time-tracker-calendar-create-dialog/time-tracker-calendar-create-dialog.component';
import { TimeTrackerCalendarUpdateDialogComponent } from '../time-tracker-calendar-update-dialog/time-tracker-calendar-update-dialog.component';
import { SelectMode } from './record-toolbar.component';
@UntilDestroy()
@ObserveFormGroupErrors()
@Injectable({
  providedIn: 'root',
})
export class RecordToolbarService {
  get configInputMode() {
    const inputMode = this.appSettings.getValue()?.inputMode ?? 'range';
    const validModes = ['range', 'duration'];
    return validModes.includes(inputMode) ? inputMode : 'range';
  }
  readonly runningTime = fromRxValue(
    this.myTimesQuery.selectAll({ filterBy: (x) => !x.end && !x['timeType'] && !x['timeGaps'], limitTo: 1 }).pipe(
      debounceTimeAfterFirst(100),
      map((times) => times[0]),
      tap((x) => {
        log.debug('runningTime', x);
      })
    )
  );

  group = new FormGroup({
    name: new FormControl('', [
      Validators.minLength(3),
      (ctrl) => {
        const user = this.userSettingsQuery.getValue();
        if (user.workspace?.settings?.requireName) {
          return Validators.required(ctrl);
        }
        return null;
      },
    ]),
    project: new FormControl<any>(
      this.projectsQuery.getAll({ filterBy: (x) => x.useAsDefault === true, limitTo: 1 })[0],
      [
        Validators.required,
        (ctrl) => {
          const value = ctrl.value as Project;
          const user = this.userSettingsQuery.getValue();
          if (
            user.workspace?.settings?.requireProject &&
            user.settings?.captureManualMode &&
            value &&
            typeof value === 'object' &&
            value.useAsDefault
          ) {
            return {
              required: true,
            };
          }
          return null;
        },
      ]
    ),
    task: new FormControl(null, (ctrl) => {
      // const value = ctrl.value as Task;
      const user = this.userSettingsQuery.getValue();
      if (user.workspace?.settings?.requireTask && user.settings?.captureManualMode && !ctrl.value?.id) {
        return {
          required: true,
        };
      }
      return null;
    }),
    billable: new FormControl(false),
    tags: new FormControl<any[]>([]),
    time: new FormGroup(
      {
        start: new FormControl('00:00', [CustomValidators.validDateFormat('HH:mm')]),
        end: new FormControl('00:00', [CustomValidators.validDateFormat('HH:mm')]),
        recordStart: new FormControl<Date>(null, [CustomValidators.validDate]),
        duration: new FormControl('00:00', [CustomValidators.validDuration()]),
      },
      [CustomValidators.timeDiffCheck('start', 'end')]
    ),
    ref: new FormControl<string>(null),
    inputMode: new FormControl<'range' | 'duration'>(this.configInputMode),
  });
  groupError(controlName: string) {
    return useFormErrorObservable(this)(
      controlName,
      () => this.group.get(controlName) as UntypedFormControl,
      {
        required: (error, ctrl) => {
          if (controlName === 'name') return { content: 'errors.time.name', args: {} };
          if (controlName === 'task') return { content: 'errors.record.desc-required', args: {} };
          if (controlName === 'project') return { content: 'errors.record.project-req', args: {} };
          return {
            content: 'errors.required',
            args: { field: controlName },
          };
        },
        minlength: (error, ctrl) => ({
          content: 'errors.minlength',
          args: { field: controlName, length: error.requiredLength },
        }),
      },
      (key) => this.translate.instant(key),
      {
        initialValidate: true,
      }
    );
  }
  readonly onClear = new Subject<void>();
  clear() {
    this.onClear.next();
    this.resetAll();
  }
  private _isLoading = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this._isLoading.asObservable().pipe(distinctUntilChanged());
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: boolean) {
    this._isLoading.next(val);
  }
  reset() {
    this.group.patchValue({
      time: {
        start: `${new Date().getHours().toString()?.padStart(2, '0')}:00`,
        end: `${new Date().getHours().toString()?.padStart(2, '0')}:00`,
        duration: `${new Date().getHours().toString()?.padStart(2, '0')}:00`,
      },
    });
  }
  resetProject(name?: boolean) {
    let data: any = {
      project: this.projectsQuery.getAll({ filterBy: (x) => x.useAsDefault === true })[0],
      task: null,
      ref: null,
    };
    if (name) data.name = '';
    this.group.patchValue(data);
  }
  resetEntites() {
    this.group.patchValue({
      name: '',
      project: this.projectsQuery.getAll({ filterBy: (x) => x.useAsDefault === true })[0],
      task: null,
      billable: false,
      tags: [],
      ref: null,
    });
    this.group.markAsUntouched();
  }
  resetDate(date?: Date) {
    this.group.patchValue({
      time: {
        recordStart: date ?? null,
      },
    });
  }
  resetAll() {
    const now = new Date();
    this.group.patchValue({
      name: '',
      project: this.projectsQuery.getAll({ filterBy: (x) => x.useAsDefault === true })[0],
      task: null,
      billable: false,
      tags: [],
      time: {
        start: `${now.getHours().toString()?.padStart(2, '0')}:00`,
        end: `${now.getHours().toString()?.padStart(2, '0')}:00`,
        duration: `${new Date().getHours().toString()?.padStart(2, '0')}:00`,
      },
      ref: null,
    });
    this.group.markAsUntouched();
  }
  constructor(
    private projectsQuery: ProjectsQuery,
    private media: MediaObserver,
    private dialog: MatDialog,
    private myTimesQuery: MyTimesQuery,
    private myTimesService: MyTimesService,
    private comegoService: ComegoService,
    private comegoQuery: ComegoQuery,
    private translate: TranslateService,
    private notifier: NotifierService,
    private appSettings: ApplicationSettingsQuery,
    private userSettingsQuery: UserSettingsQuery
  ) {}
  private initialized = false;
  private setDefaultTime() {
    const user = this.userSettingsQuery.getValue();
    const timesMode = user.workspace?.settings?.timesMode;
    let inputMode: any = this.configInputMode;
    if (timesMode === 'duration') inputMode = 'duration';
    const now = new Date();
    let start = roundToNearestMinutes(
        !user.workspace.settings?.allowFutureTimeTracking
          ? subHours(now.getTime(), 1)
          : subMinutes(new Date(now.getTime()), 15),
        { nearestTo: 15 }
      ),
      end = ((newEnd) => (isToday(newEnd) ? newEnd : endOfToday()))(addHours(start.getTime(), 1));
    if (!user.workspace.settings?.allowFutureTimeTracking) {
      if (start > new Date()) start = subMinutes(start, start.getMinutes() % 15);
      if (end > new Date()) end = subMinutes(end, end.getMinutes() % 15);

      if (differenceInHours(now, end) > 0) end = addHours(end, 1);
    }
    if (end > endOfDay(start.getTime())) {
      end = endOfDay(start.getTime());
    }
    let duration = ((d) => (~~d.hours * 60 + ~~d.minutes) * 60)(intervalToDuration({ start, end }));
    this.group.patchValue({
      time: {
        start: formatString(start, 'HH:mm'),
        end: formatString(end, 'HH:mm'),
        duration: formatString(addSeconds(startOfDay(start.getTime()), duration), 'HH:mm'),
      },
      inputMode,
    });
  }
  initialize() {
    if (!this.initialized) {
      this.initialized = true;
      this.setDefaultTime();
      this.group.valueChanges.subscribe(({ inputMode }) => {
        this.appSettings.updateByKey('inputMode', inputMode);
      });
      (this.group.controls.time as UntypedFormGroup).valueChanges
        .pipe(
          untilDestroyed(this),
          distinctUntilChangedJson(({ start, end }) => ({ start, end })),
          filter((x) => x.start !== '00:00' && x.end !== '00:00')
        )
        .subscribe(({ start, end, recordStart: date }) => {
          const refDate = new Date(date);
          let range = {
            start: clampDay(refDate)(parseFromString(start, 'HH:mm', refDate)),
            end: clampDay(refDate)(parseFromString(end, 'HH:mm', refDate)),
          };
          if (range.end < range.start) return;
          const duration = intervalToDuration({
            ...range,
          });
          (this.group.controls.time as UntypedFormGroup).patchValue(
            {
              start: formatString(range.start, 'HH:mm') || formatString(startOfDay(range.start), 'HH:mm'),
              end: formatString(range.end, 'HH:mm') || formatString(endOfDay(range.end), 'HH:mm'),
              duration: formatString(
                parseFromString(`${~~duration.hours}:${~~duration.minutes}`, 'HH:mm', new Date()),
                'HH:mm'
              ),
            },
            { emitEvent: false }
          );
        });
      combineLatest([
        this.group.controls.name.valueChanges.pipe<string>(debounceTime(1000)),
        this.group.valueChanges.pipe(debounceTime(250)),
        this.myTimesQuery.selectAll({ filterBy: (x) => !x.end }).pipe(map((x) => (x?.length > 0 ? x[0] : null))),
      ])
        .pipe(
          debounceTime(250),
          filter(([name, value, time]) => this.group.valid && time && !time.end && value?.project),
          filter(() => !this.userSettingsQuery.getValue().settings.captureManualMode && !this.isLoading)
        )
        .pipe(
          distinctUntilChanged(([oldName, oldValue, oldTime], [newName, newValue, newTime]) => {
            const time = newTime;
            if (!newName) newName = time.name;
            return (
              newName === time.name &&
              newValue.project.id === time.project.id &&
              newValue.task?.id === time.task?.id &&
              JSON.stringify((newValue.tags ?? []).map((x: Tag) => x.id).sort()) ===
                JSON.stringify((time.tags ?? []).map((x: Tag) => x.id).sort()) &&
              newValue.billable === time.billable
            );
          }),
          skip(1)
          // skipWhile(([name, value, time]) => this.group.invalid)
        )
        .pipe(
          switchMap(([name, value, time]) => {
            this.isLoading = true;
            return firstValueFrom(
              this.myTimesService.update(
                produce(time, (draft) => {
                  draft.name = name;
                  if (value.project) draft.project = value.project;
                  draft.task = value.task;
                  draft.billable = value.billable;
                  draft.tags = value.tags ?? [];
                  return draft;
                })
              )
            ).finally(() => {
              this.isLoading = false;
            });
          })
        )
        .subscribe();
      this.userSettingsQuery
        .select()
        .pipe(
          untilDestroyed(this),
          startWith(this.userSettingsQuery.getValue()),
          map((x) => x?.workspace?.settings),
          distinctUntilChangedJson()
        )
        .subscribe(() => {
          this.group.updateValueAndValidity();
        });
      this.loadAutomodeData().then((time) => {
        log.debug('last active recording', time);
        if (time) {
          this.selectedRecordMode = SelectMode.Timer;
          this.isPlaying = true;
        } else {
          this.isPlaying = false;
        }
      });
    }
  }
  private translateArgs<T extends Object>(value?: T): T {
    if (!value || typeof value !== 'object') return {} as T;
    return Object.entries(value)
      .filter((val) => val?.length && val[0] && val[1])
      .reduce((acc, [key, value]) => {
        if (key && value) acc[key] = this.translate.instant(value);
        return acc;
      }, {} as T);
  }
  notifyError<T1 extends { [key: string]: any }>(body: string, options?: ExternalToast, args?: T1, rawArgs?: T1) {
    const messageArgs = merge(this.translateArgs(args || {}) || {}, rawArgs || {});
    toast.error(
      null,
      merge(
        {},
        { ...SONNER_DEFAULT_CONFIG },
        {
          duration: 30000,
          closeButton: true,
          dismissible: true,
          description: this.translate.instant(body, messageArgs),
        } as ExternalToast,
        options
      )
    );
  }
  notifyInfo(body: string, options?: ExternalToast, args?: { [key: string]: any }) {
    toast.info(
      null,
      merge({}, { ...SONNER_DEFAULT_CONFIG }, options, {
        description: this.translate.instant(body, this.translateArgs(args || {})),
      })
    );
  }
  notifyWarn(body: string, options?: ExternalToast, args?: { [key: string]: any }) {
    toast.warning(
      null,
      merge(
        {},
        { ...SONNER_DEFAULT_CONFIG },
        {
          duration: 30000,
          closeButton: true,
          description: this.translate.instant(body, this.translateArgs(args || {})),
        } as ExternalToast,
        options
      )
    );
  }
  notifySuccess(body: string, options?: ExternalToast, args?: { [key: string]: any }) {
    toast.success(
      null,
      merge(
        {},
        { ...SONNER_DEFAULT_CONFIG },
        { description: this.translate.instant(body, this.translateArgs(args || {})) } as ExternalToast,
        options
      )
    );
  }
  hasPendingInput: boolean;
  //#region isPlaying
  private _isPlaying: boolean;
  silentTogglePlaying(playState?: boolean) {
    this._isPlaying = playState !== undefined ? playState : !this.isPlaying;
  }
  public isPlayingChange = new EventEmitter<boolean>();
  get isPlaying() {
    return this._isPlaying;
  }
  set isPlaying(val: boolean) {
    this._isPlaying = val;
    this.isPlayingChange.emit(val);
  }
  //#endregion
  //#region description
  private _taskName: string;
  public taskNameChange = new EventEmitter<string>();
  get TaskName() {
    return this._taskName;
  }
  set TaskName(val: string) {
    this._taskName = val;
    this.taskNameChange.emit(val);
    log.debug('taskname: ', this._taskName);
  }
  setTaskName(val: string) {
    this._taskName = val;
  }
  //#endregion
  //#region startTime
  private startTime: Date;
  public StartTimeChange = new EventEmitter<Date>();
  get StartTime() {
    return this.startTime;
  }
  set StartTime(val: Date) {
    this.startTime = val;
    this.StartTimeChange.emit(val);
  }
  //#endregion
  //#region startTime
  private _manualStartTime: Date = new Date();
  public ManualStartTimeChange = new EventEmitter<Date>();
  get ManualStartTime() {
    return this._manualStartTime;
  }
  set ManualStartTime(val: Date) {
    this._manualStartTime = val as Date;
    this.ManualStartTimeChange.emit(val);
  }
  private _manualEndTime: Date = new Date();
  public ManualEndTimeChange = new EventEmitter<Date>();
  get ManualEndTime() {
    return this._manualEndTime;
  }
  set ManualEndTime(val: Date) {
    this._manualEndTime = val as Date;
    this.ManualEndTimeChange.emit(val);
  }

  timeRangeSetter = new EventEmitter<{ start: Date; end: Date }>(true);
  //#endregion
  //#region selectedMode
  private _selectedRecordMode = new BehaviorSubject<SelectMode>(SelectMode.Timer);
  readonly selectedRecordMode$ = this._selectedRecordMode.asObservable().pipe(distinctUntilChanged());
  get selectedRecordMode() {
    return this._selectedRecordMode.getValue();
  }
  set selectedRecordMode(val: SelectMode) {
    this._selectedRecordMode.next(val);
  }
  private _events = new BehaviorSubject<string>(null);
  readonly events$ = this._events.asObservable().pipe(auditTime(100));
  get events() {
    return this._events.getValue();
  }
  set events(val: string) {
    this._events.next(val);
  }

  //#endregion
  //#region prj
  private _selectedProject = new BehaviorSubject<Project>(null);
  readonly selectedProject$ = this._selectedProject.asObservable().pipe(distinctUntilChanged());
  public selectedProjectChange = new EventEmitter<Project>();
  get selectedProject() {
    return this._selectedProject.getValue();
  }
  set selectedProject(val: Project) {
    this._selectedProject.next(val);
    this.selectedProjectChange.emit(val);
  }
  //#endregion
  private _selectedTags = new BehaviorSubject<Tag[]>([]);
  readonly selectedTags$ = this._selectedTags.asObservable().pipe(distinctUntilChanged());
  get selectedTags() {
    return this._selectedTags.getValue();
  }
  set selectedTags(val: Tag[]) {
    this._selectedTags.next(val);
  }
  private _billed = new BehaviorSubject<boolean>(false);
  readonly billed$ = this._billed.asObservable().pipe(distinctUntilChanged());
  get billed() {
    return this._billed.getValue();
  }
  set billed(val: boolean) {
    this._billed.next(val);
  }
  async loadAutomodeData() {
    const time = (await this.myTimesService.getLatestRecordings(1))[0];
    if (!time) return null;
    (this.myTimesQuery.__store__ as EntityStore).upsert(time.id, time);
    this.selectedRecordMode = SelectMode.Timer;
    this.group.patchValue({
      name: time.name || '',
      project: time.project?.id
        ? this.projectsQuery.getEntity(time.project.id)
        : this.projectsQuery.getAll({ filterBy: (x) => x.useAsDefault })[0],
      task: time.task,
      billable: !!time?.billable,
      tags: [],
      time: {
        start: formatString(new Date(time.start), 'HH:mm'),
        end: formatString(new Date(time.start), 'HH:mm'),
      },
    });
    this.isPlaying = true;
    return time;
  }
  async saveActiveRecording() {
    const running = await this.myTimesService.getLatestRecordings(1).then((x) => x[0]);
    return await new Promise<Time>((resolve, reject) => {
      if (running && !running.end) {
        this.isLoading = true;
        return this.myTimesService
          .update({
            ...running,
            end: new Date().toISOString(),
          })
          .toPromise()
          .then(() => {
            this.isLoading = false;
            resolve(running);
          })
          .catch(reject);
      }
      return resolve(null);
    });
  }
  async continueFromTask(entry: Task) {
    return this.continueRecording({
      name: null,
      project: entry.project as any,
      billable: false,
      task: entry,
    } as any);
  }
  async continueRecording(entry: Time) {
    const project = this.projectsQuery.getEntity(entry.project.id);
    return this.saveActiveRecording()
      .then((prevTime) => {
        if (prevTime) this.notifySuccess('record-toolbar.saved-prevtime');
        this.isLoading = true;
        this.selectedRecordMode = SelectMode.Timer;
        this.StartTime = new Date();
        this.group.patchValue({
          project,
          task: entry.task,
          name: entry.name,
          billable: !!entry.billable,
          tags: entry.tags || [],
          time: {
            recordStart: this.StartTime,
          },
        });
        return this.myTimesService
          .add({
            name: entry.name,
            start: new Date(),
            project: entry.project,
            // @ts-ignore
            task: entry.task,
            billable: !!entry.billable,
            outlookCalenderReference: entry.outlookCalenderReference,
            tags: entry.tags || ([] as any),
            end: null,
          })
          .toPromise();
      })
      .then((time) => {
        this.silentTogglePlaying(true);
        this.isLoading = false;
        return time;
      })
      .catch((err) => {
        this.isLoading = false;
        log.error(err);

        this.handleError(err);
        return Promise.reject(err);
      });
  }
  async copyRecording(entry: Time, options: Partial<{ recordMode: SelectMode }> = {}) {
    const project = this.projectsQuery.getEntity(entry.project.id) ?? { ...entry.project, client: entry.client };
    this.selectedRecordMode = options?.recordMode ?? SelectMode.Manual;
    this.events = 'COPY_ENTRY';
    this.group.patchValue({
      project,
      task: entry.task,
      name: entry.name,
      billable: !!entry.billable,
      tags: entry.tags || [],
      // @ts-ignore
      inputMode: entry.inputMode,
    });
  }
  private _saveLoading = new BehaviorSubject<boolean>(false);
  readonly saveLoading$ = this._saveLoading.asObservable().pipe(distinctUntilChanged());
  get saveLoading() {
    return this._saveLoading.getValue();
  }
  set saveLoading(val: boolean) {
    this._saveLoading.next(val);
  }

  async save(data: Partial<Time & { inputMode: 'duration' | 'range' }>) {
    this.saveLoading = true;
    const start = new Date(data.start),
      end = new Date(data.end),
      duration = data.timeDiff;
    if (start > end) {
      this.notifyError('record-toolbar.start-lt-end');
      this.saveLoading = false;
      return;
    }
    if (!data.project?.id) {
      this.saveLoading = false;
      throw new Error(extract('errors.task.not-added'));
    }
    const mode = this.userSettingsQuery.getValue()?.workspace.settings?.timesMode ?? 'range';
    const inputMode = data.inputMode;
    const taskId = data.task?.id;
    return new Promise<Time[]>((resolve, reject) => {
      this.myTimesService
        .add({
          project: { id: data.project.id },
          name: data.name,
          // @ts-ignore
          task: data.task,
          billable: !!data.billable,
          outlookCalenderReference: data.outlookCalenderReference,
          inputMode,
          ...(mode === 'range_optional'
            ? data.inputMode === 'duration'
              ? {
                  start: startOfDay(start.getTime()),
                  end: startOfDay(end.getTime()),
                  timeDiff: duration,
                }
              : {
                  start: start.toISOString(),
                  end: end.toISOString(),
                }
            : mode === 'duration'
            ? { start: startOfDay(start.getTime()), end: startOfDay(end.getTime()), timeDiff: duration }
            : {
                start: start.toISOString(),
                end: end.toISOString(),
              }),
          tags: (data.tags?.filter(({ id, name }) => id && name) ?? []) as any,
        })
        .pipe(
          finalize(() => {
            this.saveLoading = false;
          })
        )
        .subscribe({
          next: (...args) => resolve(...args),
          error: (...args) => reject(...args),
        });
    })
      .then(([x]) => {
        log.debug(x);
        const user = this.userSettingsQuery.getValue();
        const mode = user.workspace.settings?.timesMode ?? 'range';
        if (user.settings.captureManualMode === true && mode === 'range') {
          let newStart = new Date(x.end),
            newEnd = user.workspace.settings?.allowFutureTimeTracking ? addHours(new Date(x.end), 1) : new Date(x.end);
          if (!user.workspace.settings?.allowFutureTimeTracking) {
            if (differenceInHours(new Date(), newEnd) > 0) newEnd = addHours(newEnd, 1);
          }
          this.group.patchValue({
            time: {
              start: formatString(newStart, 'HH:mm'),
              end: formatString(isSameDay(newEnd, newStart) ? newEnd : endOfDay(newEnd), 'HH:mm'),
            },
          });
        }
        this.handleSuccess(x);
        if (taskId && x.task?.id != taskId) pushError('errors.times.only_assignedusers_can_book_on_task');
        this.notifySuccess('success.saved');
        this.resetEntites();
      })
      .catch((err) => {
        log.error(err);
        this.saveLoading = false;
        this.handleError(err);
        this.revertOnError(data);
      });
  }
  revertOnError(data: Partial<Time>) {
    this.group.patchValue({
      name: data.name,
      project: data.project,
      task: data.task,
      billable: data.billable,
      tags: data.tags,
      time: {
        start: formatString(new Date(data.start), 'HH:mm'),
        end: formatString(new Date(data.end), 'HH:mm'),
        duration: formatString(addSeconds(startOfToday(), data.timeDiff), 'HH:mm'),
      },
    });
  }
  openCreateDialog(data?: Partial<typeof this.group.value>) {
    const start = this.StartTime || new Date();
    const currentDt = data?.time?.recordStart || new Date(start.setHours(start.getHours(), 0, 0));
    return new Promise((resolve) => {
      return this.dialog
        .open(TimeTrackerCalendarCreateDialogComponent, {
          data: <TimeTrackCreateData>{
            title: data?.name || this.group.value.name,
            timeDiff: 0,
            start: (data?.time?.start && coerceTimeFormat(data?.time.start, currentDt)) || currentDt,
            end: (data?.time?.end && coerceTimeFormat(data?.time.end, currentDt)) || currentDt,
            date: startOfDay(currentDt),
            project: data?.project,
            task: data?.task,
            tags: data?.tags,
            billable: data?.billable,
            outlookRefId: data?.ref,
          },
        })
        .afterClosed()
        .subscribe(resolve);
    });
  }
  async openUpdateDialog(time: Time, forceUpdate: boolean = false, options?: MatDialogConfig) {
    return await firstValueFrom<Time[]>(
      this.dialog
        .open(TimeTrackerCalendarUpdateDialogComponent, {
          ...(options || {}),
          data: <CalendarEvent<{ time: Time; oldStart?: Date; oldEnd?: Date }>>{
            title: time.name,
            start: new Date(time.start),
            end: new Date(time.end),
            meta: { time, forceUpdate },
          },
        })
        .afterClosed()
    );
  }
  async startCheckin(data: Parameters<ComegoService['add']>[0]) {
    // const user = this.userSettingsQuery.getValue();
    // const lastCheckin: ComegoTime[] = await this.comegoService.adapter
    //   .get(environment.serverUrl + `/get/comego?$filter=user__id eq '${user.id}'&$orderby=end desc&$top=1`)
    //   .toPromise();
    // let lastEnd = lastCheckin?.length && DateTime.fromJSDate(new Date(lastCheckin[0].end));
    // const now = DateTime.fromJSDate(new Date());
    // if (lastEnd && !now.hasSame(lastEnd, "day") && now.diff(lastEnd, 'hours').hours < 11) {
    //   pushError('Please make sure you are considering your resting time.');
    // }
    // console.log('last', lastCheckin);
    return await this.comegoService
      .add(data)
      .then(this.handleWorkingHourSuccess.bind(this))
      .catch(this.handleWorkingHourError);
  }
  async startRecord(data?: Partial<{ name: string; project: Project; task: Task }>) {
    const project = data?.project || this.projectsQuery.getAll({ filterBy: (x) => x.useAsDefault })[0];
    if (!project) {
      const time = await this.myTimesService
        .getLatestRecordings(1)
        .then((d) => d?.[0])
        .catch((err) => {
          log.error(err);
          return null;
        });
      if (time) {
        await this.stopRecord({ requireUpdate: true });
      }
    }
    return await firstValueFrom(
      this.myTimesService.add({
        name: data?.name,
        project,
        // @ts-ignore
        task: data?.task?.project?.id === project?.id ? data?.task : undefined,
        start: new Date(),
        billable: false,
        end: null,
        recordType: 'timer',
      })
    )
      .then((time) => time || [])
      .then(([time]) => {
        if (time) {
          this.group.patchValue({
            project: time.project,
            task: time.task || null,
            name: time.name,
            tags: time.tags,
            billable: time.billable,
            ref: time.outlookCalenderReference,
            inputMode: time['inputMode'],
          });
        }
        return time;
      })
      .catch((err) => {
        this.handleError(err);
        this.revertOnError(data);
        return Promise.reject(err);
      });
  }
  async stopRecord(options?: Partial<{ showUpdate: boolean; checkServiceControls: boolean; requireUpdate: boolean }>) {
    let time = await this.myTimesService.getLatestRecordings(1).then((x) => x?.[0]);
    if (!time) return;
    if (!options) options = {};
    if (time && new Date(time.start).getDate() !== new Date().getDate()) {
      options.requireUpdate = true;
    }
    if (time && options?.requireUpdate) {
      const newTimes = await this.openUpdateDialog(time, false);
      if (!newTimes) return;
      else if (newTimes.every?.((x) => !!x.end)) {
        this.resetEntites();
        return;
      } else {
        time = newTimes?.[0];
      }
    } else if (!time) {
      (this.myTimesQuery.__store__ as MyTimesStore).remove((t) => !t.end);
      // await this.myTimesService.getLatestRecordings(1).then((x) => x?.[0]);
      return;
    }
    const now = new Date();
    const taskId = time.task?.id;
    const mode = this.userSettingsQuery.getValue().workspace.settings?.timesMode ?? 'range';
    return await firstValueFrom(
      this.myTimesService.update(
        produce(time, (draft: WithOptional<Time>) => {
          const start = mode === 'duration' ? startOfDay(new Date(draft.start)) : startOfMinute(new Date(draft.start)),
            end =
              mode === 'duration'
                ? roundUpMinute(addSeconds(start.getTime(), (now.getTime() - Date.parse(draft.start)) / 1000))
                : roundUpMinute(new Date(now.getTime()));
          draft.inputMode = 'range';
          if (mode === 'duration') {
            draft.inputMode = 'duration';
          }

          draft.start = start.toISOString();
          draft.end = end.toISOString();
          if (options?.checkServiceControls === true) {
            const value = this.group.value;
            draft.name = value.name;
            draft.project = value.project;
            draft.billable = value.billable;
            draft.task = value.task;
            if (value.ref) draft.outlookCalenderReference = value.ref;
          }
          draft.recordType = 'timer';
        })
      )
    )
      .then(([x]) => {
        this.handleSuccess(x);
        if (taskId && x.task?.id !== taskId) this.notifyInfo('errors.times.only_assignedusers_can_book_on_task');
        if (x.hasTimeOverlap && !options?.requireUpdate) return this.openUpdateDialog(x, true);
        return options?.showUpdate === false ? [x] : options?.requireUpdate ? [x] : this.openUpdateDialog(x, true);
      })
      .catch((err) => {
        this.handleError(err);
        this.revertOnError(time);
        return Promise.reject(err);
      });
  }
  handleError(err: HttpErrorResponse) {
    let args: any = {};
    if (err.error?.message === 'errors.required') args.field = err.error.field;
    pushError(err, args);
  }
  handleSuccess(x: Time) {
    if (!x) return;
    if (x.hasTimeOverlap) this.notifyInfo('time.overlaptime_not_allow_workspace');
  }
  async handleWorkingHourSuccess(times: ComegoTime[]) {
    if (!times || !times.length) return times;
    const errorMatcher = /^errors\./;
    times.forEach((t) => {
      if ('_meta' in t) {
        // handle warnings
        const meta: any = t['_meta'];
        Object.entries(meta).forEach(([key, value]: [string, any]) => {
          let errorCode: string;
          if (typeof value === 'string' && errorMatcher.test(value)) {
            this.notifyWarn(this.translate.instant(value));
            errorCode = value;
          } else if (typeof value === 'object' && 'message' in value) {
            if (errorMatcher.test(value['message'])) {
              const margs = value['args'] || {};
              this.notifyWarn(this.translate.instant(value['message'], resolveRawArgs(margs)));
              errorCode = value['message'];
            }
          }
        });
      }
    });
    return times;
  }
  async handleWorkingHourError(
    err: HttpErrorResponse,
    times?: ComegoTime[],
    options?: Partial<{ forceDialogError: boolean; forceUpdateTime: boolean }>
  ) {
    let errorCode: string;
    const errorRaw = (errorCode = (typeof err.error === 'object' && err.error?.message) || err.error);
    if (!errorCode || !/^errors\./.test(errorCode)) return;
    options = merge({}, options || {});
    const errorMessage = errorCode;
    const errorArgs = ('args' in err.error && typeof err.error === 'object' && resolveRawArgs(err.error.args)) || {};
    errorCode = errorCode.slice(errorCode.lastIndexOf('.') + 1);
    const isBreakError = isBreakRuleError(err.error);
    if (options.forceUpdateTime || options.forceDialogError || isBreakError) {
      if (!times?.length) return await Promise.reject(err);
      if (isBreakError) {
        const start = addMinutes(startOfMinute(new Date()), 1); // prevents overlap
        const end = addMinutes(start.getTime(), (err.error.args && err.error.args.minutesDue * 60) || 30);
        await this.dialog
          .open(ComeAndGoCreateDialogComponent, {
            data: <WorkingHoursCreateData>{
              initialTimes: [
                {
                  start,
                  end,
                  type: 'pause',
                },
              ],
              error: {
                args: errorArgs,
                message: errorRaw,
              },
              hiddenFields: ['name'],
              disabledFields: ['name', 'time.type', 'time.add', 'date', 'user'].concat(
                options.forceDialogError ? 'time' : []
              ),
              allowedTimeTypes: ['pause'],
              disabled: !!options.forceDialogError,
            },
          })
          .afterClosed()
          .toPromise()
          .then(this.handleWorkingHourSuccess.bind(this))
          .catch(this.handleWorkingHourError.bind(this));
      } else
        await this.dialog
          .open(ComeAndGoCreateDialogComponent, {
            data: <WorkingHoursCreateData>{
              time: {
                value: times?.[0],
                correctTimeRanges: true,
                deleteOriginTime: true,
                lockItem: true,
              },
              error: {
                args: errorArgs,
                message: errorMessage,
              },
              hiddenFields: ['name'],
              disabledFields: ['name', 'time.type', 'date', 'user'].concat(options.forceDialogError ? 'time' : []),
              allowedTimeTypes: ['work', 'pause'],
              disabled: !!options.forceDialogError,
            },
          })
          .afterClosed()
          .toPromise()
          .then(this.handleWorkingHourSuccess.bind(this))
          .catch(this.handleWorkingHourError.bind(this));
    }
    return await Promise.reject(err);
  }
  setToNow(prop: 'start' | 'end') {
    return this.group.patchValue({
      time: {
        [prop]: flow((x) => formatString(x, 'HH:mm'))(new Date()),
      },
    });
  }
  setMinutesDiff(minutes: number, prop: 'start' | 'end') {
    const timespanGroup = this.group.get('time') as UntypedFormGroup;
    switch (prop) {
      case 'start':
        return timespanGroup.patchValue({
          [prop]: flow(
            (x) => parseFromString(x, 'HH:mm', new Date()),
            (x) => subMinutes(x, minutes),
            clampDay(new Date()),
            (x) => formatString(x, 'HH:mm')
          )(timespanGroup.value.end as string),
        });
      case 'end':
        return timespanGroup.patchValue({
          [prop]: flow(
            (x) => parseFromString(x, 'HH:mm', new Date()),
            (x) => addMinutes(x, minutes),
            clampDay(new Date()),
            (x) => formatString(x, 'HH:mm')
          )(timespanGroup.value.start as string),
        });
    }
  }
  setHourDiff(hours: number, prop: 'start' | 'end') {
    const timespanGroup = this.group.get('time') as UntypedFormGroup;
    switch (prop) {
      case 'start':
        return timespanGroup.patchValue({
          [prop]: flow(
            (x) => parseFromString(x, 'HH:mm', new Date()),
            (x) => subHours(x, hours),
            clampDay(new Date()),
            (x) => formatString(x, 'HH:mm')
          )(timespanGroup.value.end as string),
        });
      case 'end':
        return timespanGroup.patchValue({
          [prop]: flow(
            (x) => parseFromString(x, 'HH:mm', new Date()),
            (x: Date) => addHours(x, hours),
            clampDay(new Date()),
            (x) => formatString(x, 'HH:mm')
          )(timespanGroup.value.start as string),
        });
    }
  }
  setWorkDay() {
    this.group.patchValue({
      time: {
        start: '09:00',
        end: '17:00',
      },
    });
  }
}
const log = new Logger(RecordToolbarService.name);
