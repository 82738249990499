import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { ClientProjectModel } from '@app/classes/client-project-model';
import { createClientProjectSearch } from '@app/_helpers/search';
import { createRxValue, distinctUntilChangedJson, hasPermission } from '@app/_helpers/utils';
import { DefaultEntityTranslatePipe } from '@app/_pipes/default-entity-translate.pipe';
import { UtilService } from '@app/_services/util.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, map, startWith, tap } from 'rxjs/operators';
import { firstBy } from 'thenby';
import {
  Client,
  ClientsQuery,
  Logger,
  Project,
  ProjectsQuery,
  Task,
  TasksQuery,
  TasksService,
  UserService,
  UserSettings,
  UserSettingsQuery,
} from 'timeghost-api';

import { CreateProjectComponent } from '../create-project/create-project.component';
import { ClientProjectEntry } from './client-project-entry/client-project-entry.component';

const log = new Logger('ClientProjectPickerDialogComponent');
type ProjectWithMeta = Project & {
  selected?: boolean;
  favourite?: boolean;
};
type ClientProjectModelWithMeta = ClientProjectModel & { viewFavourite?: boolean; viewSelected?: boolean };
@UntilDestroy()
@Component({
  selector: 'app-client-project-picker-dialog',
  templateUrl: './client-project-picker-dialog.component.html',
  styleUrls: ['./client-project-picker-dialog.component.scss'],
  host: {
    class: 'search-dialog-host',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientProjectPickerDialogComponent implements OnInit, AfterViewInit, AfterViewChecked {
  private _isLoading = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this._isLoading.asObservable().pipe(startWith(), distinctUntilChanged());
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: boolean) {
    this._isLoading.next(val);
  }

  readonly workspace$canManageProject = this.userSettingsQuery.select(
    (x) => !!x.workspace.permissionSettings.groupsCanManageProjects.find((y) => hasPermission(y.id, x))
  );
  readonly selectedProject = createRxValue<Project>(null);
  readonly selectedTask = createRxValue<Task>(null);
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private src: ClientProjectDialogData,
    private ref: MatDialogRef<ClientProjectPickerDialogComponent>,
    private utilService: UtilService,
    private dialog: MatDialog,
    private appService: AppService,
    private clientsQuery: ClientsQuery,
    private projectsQuery: ProjectsQuery,
    private userSettingsQuery: UserSettingsQuery,
    private userService: UserService,
    private tasksQuery: TasksQuery,
    private tasksService: TasksService,
    private translateName: DefaultEntityTranslatePipe,
    private cdref: ChangeDetectorRef,
    private router: Router
  ) {}
  get userSettings() {
    return this.userSettingsQuery.getValue();
  }
  searchinput: UntypedFormControl = new UntypedFormControl('');
  readonly data$filtered = combineLatest([
    this.searchinput.valueChanges.pipe(startWith(''), debounceTime(150), distinctUntilChanged()),
    this.projectsQuery.selectAll({ filterBy: (x) => !x.completed && (!x.projectType || x.projectType === 'project') }),
    this.userSettingsQuery.select().pipe(
      map((x) => x.pinnedProjects),
      distinctUntilChangedJson()
    ),
    this.selectedProject.asObservable(),
    this.selectedTask.asObservable(),
    this.userSettingsQuery.select(),
  ]).pipe(
    filter(
      ([, data, pinnedProjects, selected, task, user]: [any, Project[], string[], Project, Task, UserSettings]) =>
        !!data
    ),
    map(([search, _data, pinnedProjects, project, task, user]) => {
      return [search, _data, pinnedProjects, project, task, user];
    }),
    map(([search, _data, pinnedProjects, _selected, task]: [string, Project[], string[], Project, Task]) => {
      const selected = (_selected?.id ? _data.find((x) => x.id === _selected.id) : null) || _selected;
      const data = _data.map((x: Project): ProjectWithMeta => {
        return {
          ...x,
          selected: selected?.id === x.id,
          favourite: pinnedProjects.findIndex((y) => y === x.id) !== -1,
        };
      });
      return [search, data, pinnedProjects, selected, task];
    }),
    map(([search, data, pinnedProjects, ...props]: [string, ProjectWithMeta[], string[], ...any[]]) => {
      const ret = ((d) =>
        Object.values(d).map((x, i) => {
          x.id = i;
          return x;
        }))(
        (() => {
          if (search && search.trim().length > 0) {
            return createClientProjectSearch(data, {
              translateKeys: {
                client: (entity) => this.translateName.transform(entity, 'client', 'client.none'),
                project: (entity) => this.translateName.transform(entity, 'project', 'project.none'),
              },
            })
              .search(search)
              .map((x): ProjectWithMeta => x.project);
          } else {
            return data;
          }
        })().reduce((clientProjectData, p: ProjectWithMeta) => {
          if (!clientProjectData[p.client.id])
            clientProjectData[p.client.id] = new ClientProjectModel({ projects: [], client: p.client as Client });
          if (p.selected) clientProjectData[p.client.id].viewSelected = true;
          if (p.favourite) clientProjectData[p.client.id].viewFavourite = true;
          clientProjectData[p.client.id].projects.push(p);
          return clientProjectData;
        }, {} as { [key: string]: ClientProjectModelWithMeta })
      );
      return [ret, pinnedProjects, search, ...props];
    }),
    map(([data, pinnedProjects, search, , task]: [ClientProjectModelWithMeta[], string[], string, Project, Task]) => {
      const newData = ((favourites, selected, arr) => {
        const preHeadData: ClientProjectModelWithMeta[] = [];
        if (!search || search.trim().length <= 0) {
          preHeadData.push({
            id: -1,
            client: {
              id: 'internal',
              name: 'project.favourite',
            } as any,
            projects: [
              ...favourites.reduce((l, r) => {
                l.push(
                  ...r.projects
                    .filter(
                      (x) =>
                        //@ts-ignore
                        x.favourite
                    )
                    .map((f) => ({
                      ...f,
                      ...(selected?.projects.find((x) => x['selected'] && x.id === f.id)
                        ? { preventAutoTasksShow: true }
                        : {}),
                    }))
                );
                return l;
              }, []),
            ].filter(Boolean),
            // @ts-ignore
            viewFavourite: true,
          });
          if (selected)
            preHeadData.push({
              id: -2,
              client: {
                id: 'internal',
                name: 'utils.selected',
              } as any,
              projects: [
                {
                  // @ts-ignore
                  ...selected.projects.find((x) => x.selected),
                  selected: true,
                  task: task?.id,
                } as any,
              ],
              // @ts-ignore
              viewSelected: true,
            });
        }
        return [
          ...preHeadData.sort((b, a) => a.id - b.id),
          ...arr
            .map((x) => ({
              ...x,
              client: this.clientsQuery.getEntity(x.client?.id) ?? x.client,
              projects: x.projects
                .map((p) => {
                  return (
                    preHeadData
                      .find((phd) => phd.projects.find((o) => o.id === p.id))
                      ?.projects.find((phd) => phd.id === p.id) || p
                  );
                })
                .sort(firstBy((x) => x.name)),
            }))
            .filter((x) => x.projects.length > 0)
            .sort(firstBy((x) => x.client.name)),
        ].filter(Boolean);
      })(
        data.filter((x) => x.viewFavourite),
        data.find((x) => x.viewSelected),
        data
      );
      return [newData, pinnedProjects];
    }),
    map(([x, pinnedProjects]: [ClientProjectModel[], string[]]) => {
      if (!x) return [];
      const sortInternal = (a: ClientProjectModel, b: ClientProjectModel) => {
        if (b.client.id === 'internal' && a.client.id === 'internal') {
          return ~~(a.id - b.id);
        }
        // @ts-ignore
        return (b.client?.id === 'internal') - (a.client?.id === 'internal');
      };
      if (!pinnedProjects || pinnedProjects.length === 0) return x.sort(sortInternal);
      const isPinned = (id: string) => pinnedProjects.indexOf(id) !== -1;
      return x
        .sort(
          (a, b) =>
            ~~(b.projects.findIndex((p) => isPinned(p.id)) !== -1) -
              ~~(a.projects.findIndex((p) => isPinned(p.id)) !== -1) ||
            ~~b.client.useAsDefault ||
            // @ts-ignore
            0 - !!a.client.useAsDefault ||
            0
        )
        .sort(sortInternal);
    }),
    map((x) => {
      return x.reduce((l, r) => {
        l.push({ ...r.client, entityName: 'client' }, ...r.projects.map((x) => ({ ...x, entityName: 'project' })));
        return l;
      }, []);
    }),
    tap((x) => log.debug('finalized data', x)),
    finalize(() => this.cdref.detectChanges())
  );
  isPinned(id: string) {
    return this.userSettings.pinnedProjects && this.userSettings.pinnedProjects.indexOf(id) !== -1;
  }
  togglePinnedProject(id: string) {
    return (
      !this.isPinned(id) ? this.userService.addPinnedProject(id) : this.userService.removePinnedProject(id)
    ).toPromise();
  }
  isSelected(projectId: string) {
    return this.selectedProject.value?.id === projectId;
  }
  onSelectItem([ev, project]: [Event, ClientProjectEntry]) {
    ev.preventDefault();
    return this.selectItem(project);
  }
  @ViewChild('projectContextMenuTrigger')
  projectContextMenuTrigger: MatMenuTrigger;
  onContextMenu([ev, project]: [Event, Project]) {
    return this.openProjectContextMenu(ev, this.projectContextMenuTrigger, { $implicit: project });
  }
  selectItem(project: Project) {
    const defaultProject = this.src.data.defaultProject;
    let selectedProject = this.selectedProject.value ?? defaultProject,
      selectedTask = this.selectedTask.value;

    if (selectedTask && (!selectedTask?.project || selectedTask.project.id !== project.id))
      this.selectedTask.value = selectedTask = null;
    if (this.src.data.canToggle && !!selectedProject) {
      if (project.id === selectedProject.id) {
        this.selectedProject.value = selectedProject = defaultProject;
        if (this.src.data.closeOnRemove) {
          this.close(selectedProject, selectedTask);
        }
        return;
      }
    }
    this.selectedProject.value = project;
    this.selectedTask.value = selectedTask;
    this.close(project, selectedTask);
  }
  onSelectTaskItem([ev, project, task]: [Event, Project, Task & { selected?: boolean }]) {
    this.selectedProject.value = project || this.src.data.defaultProject || null;
    this.selectedTask.value = this.selectedTask.value?.id === task?.id ? null : task ?? null;
    return this.close(this.selectedProject.value, this.selectedTask.value);
  }
  ngAfterViewInit() {
    this.cdref.detectChanges();
  }

  projectContextMenuPosition = { x: '0px', y: '0px' };
  openProjectContextMenu(event: MouseEvent | Event, trigger: MatMenuTrigger, data: any) {
    event.stopPropagation(), event.preventDefault();
    if (event instanceof MouseEvent) {
      this.projectContextMenuPosition.x = event.clientX + 'px';
      this.projectContextMenuPosition.y = event.clientY + 'px';
    } else {
      const { left, top } = (event.currentTarget! as HTMLElement)?.getClientRects()[0];
      this.projectContextMenuPosition.x = left + 'px';
      this.projectContextMenuPosition.y = top + 'px';
    }
    trigger.menuData = data;
    trigger.menu.focusFirstItem('mouse');
    trigger.openMenu();
  }
  createProjectDialog() {
    this.dialog
      .open(CreateProjectComponent, {
        data: {
          search: this.searchinput.value,
        },
      })
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe((x: Project) => this.selectItem(x));
  }
  trackFiltered(index: number, obj: ClientProjectModel) {
    return obj.id;
  }
  ngOnInit() {
    this.selectedProject.value = this.src.data.selectedProject;
    this.selectedTask.value = this.src.data.selectedTask;
    this.ref._containerInstance._config.autoFocus = true;
    this.ref._containerInstance._config.closeOnNavigation = true;
    this.ref.addPanelClass('client-project-picker-dialog-container');
    this.ref.updateSize('460px');
    if (
      this.selectedProject.value?.id &&
      this.tasksQuery.getCount((x) => x.project.id === this.selectedProject.value.id) <= 1
    ) {
      this.isLoading = true;
      this.tasksService
        .getByProject(this.selectedProject.value)
        .pipe(
          untilDestroyed(this),
          finalize(() => (this.isLoading = false))
        )
        .subscribe();
    }
  }
  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }
  openProjectPage(id: string) {
    this.isLoading = true;
    return this.router
      .navigate(['/settings/projects', id])
      .then((x) => {
        this.ref.close([]);
        this.dialog.closeAll();
      })
      .catch((err) => {
        this.ref.close([]);
        this.dialog.closeAll();
        this.isLoading = false;
        this.appService.notifier.show({
          type: 'error',
          message: 'Could not load Project',
        });
      });
  }
  isDefaultClient(id: string) {
    return this.clientsQuery.getEntity(id)?.useAsDefault === true;
  }
  isDefaultProject(id: string) {
    return this.projectsQuery.getEntity(id)?.useAsDefault === true;
  }
  trackId(index: number, { id }: { id: string }) {
    return id;
  }
  close(project: Project, task?: Task) {
    this.ref.close([project, task?.project?.id ? (task.project.id !== project.id ? null : task) : null]);
  }
}
export interface ClientProjectDialogData {
  anchor: DialogAnchor;
  data: {
    selectedProject: Project;
    selectedTask: Task;
    canToggle: boolean;
    closeOnRemove?: boolean;
    defaultProject?: Project;
  };
}
export interface DialogAnchor {
  triggerElementRef: ElementRef;
}
