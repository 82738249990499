import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, defer } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { UserImagesQuery, UserService, UserSettingsQuery } from 'timeghost-api';
@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent implements OnInit, OnDestroy {
  readonly destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  private _isLoading = new BehaviorSubject<boolean>(true);
  readonly isLoading$ = this._isLoading.asObservable().pipe(distinctUntilChanged());
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: boolean) {
    this._isLoading.next(val);
  }
  private _defaultImageSource = new BehaviorSubject<string>('/assets/img/default.png');
  readonly defaultImageSource$ = this._defaultImageSource.asObservable().pipe(distinctUntilChanged());
  get defaultImageSource() {
    return this._defaultImageSource.getValue();
  }
  @Input()
  defaultImageTemplate: TemplateRef<any>;
  @Input()
  defaultImageTemplateData: any = {};
  @Input()
  set defaultImageSource(val: string) {
    this._defaultImageSource.next(val);
  }

  constructor(
    private ua: UserService,
    private userImages: UserImagesQuery,
    private userSettingsQuery: UserSettingsQuery
  ) {}

  ngOnInit() {
    defer(() => {
      this.isLoading = true;
      return this.image$;
    }).subscribe({
      next: (x) => {
        this.imageSrc = x;
        this.isLoading = false;
      },
      error: () => (this.isLoading = false),
    });
  }
  private _userId = new BehaviorSubject<string>(null);
  readonly userId$ = this._userId.asObservable().pipe(distinctUntilChanged());
  get userId() {
    return this._userId.getValue();
  }
  @Input()
  set userId(val: string) {
    this._userId.next(val);
  }
  private _sourceSize = new BehaviorSubject<number>(96);
  readonly sourceSize$ = this._sourceSize.asObservable().pipe(distinctUntilChanged());
  get sourceSize() {
    return this._sourceSize.getValue();
  }
  @Input()
  set sourceSize(val: number) {
    this._sourceSize.next(val);
  }
  private _size = new BehaviorSubject<number>(32);
  readonly size$ = this._size.asObservable().pipe(distinctUntilChanged());
  get size() {
    return this._size.getValue();
  }
  set size(val: number) {
    this._size.next(val);
  }

  private _imageSrc = new BehaviorSubject<string | ArrayBuffer>(null);
  readonly imageSrc$ = this._imageSrc.asObservable().pipe(distinctUntilChanged());
  get imageSrc() {
    return this._imageSrc.getValue();
  }
  set imageSrc(val: string | ArrayBuffer) {
    this._imageSrc.next(val);
  }

  readonly image$ = combineLatest([
    this.userId$.pipe(startWith(this.userId)),
    this.sourceSize$.pipe(startWith(this.sourceSize)),
  ]).pipe(
    switchMap(([uid, size]) =>
      uid === 'default'
        ? null
        : this.userImages.hasEntity(uid || this.userSettingsQuery.getValue().id)
        ? this.userImages.selectEntity(uid).pipe(map((x) => x.image))
        : !uid
        ? this.ua.getCurrentUserPhoto(size ? (`${size}x${size}` as any) : '96x96').catch(() => null)
        : this.ua.getUserPhotoById(uid, size ? (`${size}x${size}` as any) : '96x96').catch(() => null)
    ),
    takeUntil(this.destroy$)
  );
}
