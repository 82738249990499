import { Component, OnInit, Inject, AfterViewInit, AfterContentInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ClientsService, ClientsQuery, UserSettingsQuery } from 'timeghost-api';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { finalize, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CustomValidators } from '@app/_validators/custom-validators';
import { TranslateService } from '@ngx-translate/core';
import { hasPermission } from '@app/_helpers/utils';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss'],
})
export class CreateClientComponent implements OnInit, AfterContentInit {
  csGroup: UntypedFormGroup = new UntypedFormGroup(
    {
      name: new UntypedFormControl('', [Validators.required, CustomValidators.minLength(3)]),
      description: new UntypedFormControl(''),
    },
    {
      validators: [
        ({ root: ctrl }) => {
          const ctrls = (ctrl as UntypedFormGroup).controls;
          if (this.clientQuery.getCount((x) => x.name?.toLowerCase() === `${ctrls.name.value}`.toLowerCase()) > 0) {
            ctrls.name.setErrors({ ...ctrls.name.errors, exists: true });
            return { exists: true };
          }
          return null;
        },
      ],
      updateOn: 'change',
    }
  );
  get csName() {
    return this.csGroup.controls.name;
  }
  get csDescription() {
    return this.csGroup.controls.description;
  }
  private _isLoadingAsync: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoadingAsync = this._isLoadingAsync.asObservable();
  set isLoading(val: boolean) {
    this._isLoadingAsync.next(val);
  }

  readonly workspace$createPermission = this.userSettingsQuery.select().pipe(
    map((userSettings) => {
      return !!userSettings.workspace.permissionSettings?.groupsCanManageClients?.find((x) =>
        hasPermission(x.id, userSettings)
      );
    })
  );
  get csErrors() {
    if (this.csName.errors?.required) return { content: 'errors.required', args: { field: 'Name' } };
    if (this.csName.errors?.minlength) return { content: 'errors.minlength', args: { field: 'Name', length: 3 } };
    if (this.csName.errors?.exists) return { content: 'errors.unique', args: { field: 'Name' } };
    return null;
  }
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { name?: string; search?: string },
    private ref: MatDialogRef<CreateClientComponent>,
    private clientService: ClientsService,
    private translate: TranslateService,
    private userSettingsQuery: UserSettingsQuery,
    private clientQuery: ClientsQuery
  ) {}
  readonly workspace$ = this.userSettingsQuery.select((x) => x.workspace);

  ngOnInit() {
    this.ref.updateSize('500px');
    this.ref.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative']);
    if (this.data?.search) {
      this.csGroup.controls.name?.setValue(this.data.search);
    }
  }
  ngAfterContentInit() {
    this.csGroup.updateValueAndValidity();
  }
  addEntry() {
    this.isLoading = true;
    this.ref.disableClose = true;
    this.clientService
      .add({
        ...this.csGroup.value,
      })
      .pipe(finalize(() => (this.ref.disableClose = false)))
      .subscribe((x) => this.ref.close(x));
  }
}
