import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { createRxValue, distinctUntilChangedJson } from '@app/_helpers/utils';
import startWithDefault from '@app/_operators/startWithDefault';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserSettingsQuery, Workspace } from 'timeghost-api';
type User = Workspace['users'][0];
export interface UserSinglePickerDialogData {
  selected: User;
  filter?(user: User & { selected?: boolean }): boolean;
}
@Component({
  selector: 'tg-user-single-picker-dialog',
  templateUrl: './user-single-picker-dialog.component.html',
  styleUrls: ['./user-single-picker-dialog.component.scss'],
})
export class UserSinglePickerDialogComponent implements OnInit {
  readonly search = new UntypedFormControl('');
  readonly selected = createRxValue<User>(this.data?.selected);
  readonly data$filtered = combineLatest([
    this.search.valueChanges.pipe(startWithDefault()),
    this.userSettingsQuery.select().pipe(map((x) => x?.workspace?.users ?? [])),
    this.selected.asObservable(),
  ]).pipe(
    distinctUntilChangedJson(),
    map(([q, users, selected]) => {
      const newUsers = (() => {
        if (!q) return users;
        return users.filter(({ name, email }) =>
          [name, email].some((x) => x.toLowerCase().indexOf(q.toLowerCase()) !== -1)
        );
      })();
      if (selected) return newUsers.map((x) => ({ ...x, selected: x.id === selected.id }));
      if (this.data.filter) return newUsers.filter(this.data.filter);
      return newUsers;
    })
  );
  constructor(
    private ref: MatDialogRef<UserSinglePickerDialogComponent>,
    private userSettingsQuery: UserSettingsQuery,
    @Inject(MAT_DIALOG_DATA) private data: UserSinglePickerDialogData
  ) {}

  ngOnInit(): void {
    this.ref.addPanelClass('user-single-picker-container');
    this.ref.updateSize('460px');
  }
  selectItem(user: User) {
    this.selected.value = user;
    return this.ref.close(user);
  }
  trackId(index: number, { id }: { id: string }) {
    return id;
  }
}
