import { HttpErrorResponse } from '@angular/common/http';
import { resolveRawArgs } from './utils';

export interface ErrorResponse<T = any> {
  message: string;
  args: T;
}
interface BreakRuleError extends ErrorResponse<{ minutesDue: number; workingHours: number }> {}
/**
 *
 * @param value HttpErrorResponse error object
 * @returns
 */
export function isBreakRuleError(value: any): value is BreakRuleError {
  return typeof value === 'object' && /^errors\.times\.comego\.breakRuleNotice/.test(value.message);
}
/**
 *
 * @param value HttpErrorResponse error object
 * @returns
 */
export function isWorkingHoursError<T = any>(value: any): value is ErrorResponse<T> {
  const errorMessage = (typeof value === 'object' && value.message) || (typeof value === 'string' && value);
  if (errorMessage === false) return false;
  return /^errors\.times\.comego/.test(errorMessage);
}
export function parseErrorResponse<T>(err: HttpErrorResponse): ErrorResponse<T> {
  const errorMessage = (typeof err.error === 'object' && err.error.message) || err.error;
  const errorArgs = (typeof err.error === 'object' && 'args' in err.error && err.error.args) || {};
  let error: ErrorResponse = {
    message: errorMessage,
    args: resolveRawArgs(errorArgs),
  };
  return error as ErrorResponse;
}
